<template>
  <div class="fixed_bottom">
    <div class="row mx-0 align-items-center justify-content-center">
      <div class="col-auto green-text title bold mb-2">
        QUICK DONATE
      </div>
    </div>
    <div class="row mx-0 align-items-center justify-content-center">
      <div class="col-auto bg px-2" ref="theHolder">
        <div class="background"></div>
        <div class="row mx-0 h-100 above_z justify-content-center align-items-center">
          <div class="col-auto text-center">
            <div class="row justify-content-center">
              <DefaultAmountSelect v-model="selectedAmount" @useSelected="useSelected" />
              <CurrencyField type="text" placeholder="0.00" id="customamount" v-model="customAmount" :currencySymbol="defaultCurrency" @checkAmount="checkAmount" @customFocus="customFocus" />
            </div>
          </div>
          <div class="col px-1" v-if="isExpanded">
            <SelectAll
              v-if="selectedProject && selectedProject.length > 0"
              v-model="donationType"
              id="project"
              :options="selectedProject[0].projectDonationTypeMaps"
              placeholder="Donation Type"
              displayValue="donationType"
              subDisplayValue="description"
              color="gold"
              class="mt-1"
              :goUp="true"
              :disabled="selectedProject.length === 0"
            />
          </div>
          <div class="col-auto px-1" v-if="isExpanded">
            <Button color="gold" btnText="Added" icon="arrow" disabled="disabled" v-if="isAdded" />
            <Button color="goldGreen" btnText="Add to Cart" icon="arrow" @buttonClicked="addDonationToCart" v-else :disabled="donation.amount < 0.01 || Object.keys(selectedProject).length === 0 || !donationType">
              <IconCart color="gold" size="size16" />
            </Button>
          </div>
          <div class="col-auto ps-1 pe-0">
            <Button color="red" :btnText="donateButtonText" icon="arrow" @buttonClicked="goDonate" :disabled="donation.amount < 0.01 || Object.keys(selectedProject).length === 0 || !donationType">
              <IconArrowForward color="white" />
            </Button>
          </div>
        </div>
      </div>
    </div>
    <transition name="comeIn">
      <AddCartPopup v-if="isAdded" @close="closeAdded"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    AddCartPopup: defineAsyncComponent(() => import('@/views/AddCartPopup.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    CurrencyField: defineAsyncComponent(() => import('./components/CurrencyField.vue')),
    DefaultAmountSelect: defineAsyncComponent(() => import('./components/DefaultAmountSelect.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconCart: defineAsyncComponent(() => import('@/components/icons/IconCart.vue'))
  },
  name: 'Quick Donate Sticky',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isAdded: false,
      isExpanded: false,
      selectedAmount: '5',
      customAmount: '',
      donation: {
        amount: '5',
        currency: this.defaultCurrency,
        projectId: null,
        donationTypes: []
      },
      donationType: '',
      theWidth: null
    }
  },
  watch: {
    selectedProject () {
      if (this.selectedProject && this.selectedProject.length > 0) {
        this.defaultDonationType()
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    selectedProject () {
      if (this.isUK) {
        return this.adminProjects.filter(project => {
          if (project.description.toLowerCase() !== 'most in need') {
            return false
          } else {
            return true
          }
        })
      } else {
        return this.adminProjects.filter(project => {
          if (project.projectID !== 28) {
            return false
          } else {
            return true
          }
        })
      }
    },
    donateButtonText () {
      let ret = 'Go'
      if (this.isExpanded) {
        ret = 'Make Donation'
      }
      return ret
    }
  },
  beforeMount () {
    this.donation.currency = this.defaultCurrency
  },
  mounted () {
    this.matchWidth()
    if (this.selectedProject && this.selectedProject.length > 0) {
      this.defaultDonationType()
    }
    this.setDefaultAmount()
  },
  methods: {
    ...mapActions([
      'addToCart'
    ]),
    checkAmount () {
      this.isExpanded = true
      if (this.customAmount || this.customAmount === 0 || this.customAmount === '') {
        this.selectedAmount = null
        this.donation.amount = this.customAmount
      }
    },
    customFocus () {
      this.isExpanded = true
      this.selectedAmount = null
      this.donation.amount = this.customAmount
    },
    useSelected () {
      this.isExpanded = true
      this.customAmount = null
      this.donation.amount = this.selectedAmount
    },
    defaultDonationType () {
      if (this.selectedProject && this.selectedProject.length > 0) {
        this.selectedProject[0].projectDonationTypeMaps.forEach((dType) => {
          if (dType.donationType.donationTypeID === 3) {
            this.donationType = dType
          }
        })
      }
    },
    matchWidth () {
      const width = this.$refs.theHolder.offsetWidth
      this.theWidth = width
    },
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    closeAdded () {
      this.isAdded = false
      this.isExpanded = false
      this.setDefaultAmount()
    },
    addDonationToCart () {
      this.selectedProject[0].projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.donation.projectId = this.selectedProject[0].projectID
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: null,
        donationTypes: []
      }
    },
    setDefaultAmount () {
      if (this.isUK) {
        this.selectedAmount = '5'
        this.donation.amount = 5
      } else {
        this.selectedAmount = '100'
        this.donation.amount = 100
      }
    }
  }
}
</script>

<style scoped>
.fixed_bottom {
  position: fixed;
  bottom: 25px;
  width: 100vw;
  z-index: 3;
  text-align: center;
  justify-content: center;
}
.bg {
  /* width: max-content; */
  max-width: 842px;
  height: 72px;
  position: relative;
  border-radius: 5rem;
  background-image: linear-gradient(rgb(26, 104, 54) 11%, rgb(26, 104, 54) 93%);
  padding-left: 17.5px;
  padding-right: 17.5px;
  /* border: 2px solid #fff; */
}
.background {
  position: absolute;
  background-color: rgb(26, 104, 54);
  background-image: url(../../assets/images/bg.png);
  background-size: 24%;
  opacity: 0.05;
  border: none;
  width: 100%;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 40px;
  padding: 0;
  z-index: 1;
  border-radius: 4rem;
}
.above_z {
  position: relative;
  z-index: 2;
}
.title {
  font-size: 12px;
  letter-spacing: 4.5px;
  line-height: 1;
}
</style>
